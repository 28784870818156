import React, { useEffect, useState, useRef } from "react";
import { window } from 'browser-monads';
import Layout from "../components/Layout";
import styled from "styled-components";
import currentFrame from "../utils/images";
import { mobileAndDown } from "../utils/styleUtils";
import { graphql, Link } from "gatsby";
import { gsap, ScrollTrigger } from "gsap/all";
import TextLoop from "react-text-loop";
import DownloadOnAppStore from "../assets/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg";
import TipRect from "../components/TipRect";
import CreatorSection from "../components/CreatorSection";
import {
    PhoneContainer,
    PhoneImage,
    TipStepText,
    TipSpan,
    Title,
    SubTitle,
    TipTextContainer,
} from "../components/UiComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

gsap.registerPlugin(ScrollTrigger);

// GEOFFERY
// You can change Tip by changing the tip id HERE
export const query = graphql`
    {
        Tip {
            one: getTip(id: "5000d866-299a-45a2-923a-6d76eb413ff0") {
                authorID
                id
                title
                subtitle
                introduction
                headerImage
            }
            two: getTip(id: "b8b0e973-a283-475b-8014-feaf3d25b841") {
                authorID
                id
                title
                subtitle
                introduction
                headerImage
            }
            three: getTip(id: "28145563-bea9-4d4b-8a63-90f4f05a0b57") {
                authorID
                id
                title
                subtitle
                introduction
                headerImage
            }
            creatorOne: getCreator(
                id: "001479.bfb9f651730048c599e3a77fdc2bc598.1613"
            ) {
                givenName
                tipIDs
                image
                location
            }
            creatorTwo: getCreator(
                id: "001848.d6ef93b967954e9bb65e6ce78c6fa750.1814"
            ) {
                givenName
                tipIDs
                image
                location
            }
            creatorThree: getCreator(
                id: "001687.9dda9ae6d74d4f7bb3590f9a1235337c.1345"
            ) {
                givenName
                tipIDs
                image
                location
            }
        }
    }
`;

const Home = ({ data }) => {
    // const [textIndex, setTextIndex] = useState(0);
    const [chart1Height, setChart1Height] = useState(null);
    const [chart2Height, setChart2Height] = useState(null);
    const [chart3Height, setChart3Height] = useState(null);
    const [chart4Height, setChart4Height] = useState(null);
    const [chart5Height, setChart5Height] = useState(null);
    const [innerHeight, setInnerHeight] = useState(null);
    const [loaded, setLoaded] = useState(null);

    useEffect(() => {
        if (!loaded && (text1Ref !== null || text1Ref !== undefined)) {
            console.log("LOADED", text1Ref);
            setChart1Height(text1Ref.current.offsetHeight);
            setChart2Height(text2Ref.current.offsetHeight);
            setChart3Height(text3Ref.current.offsetHeight);
            setChart4Height(text4Ref.current.offsetHeight);
            setChart5Height(text5Ref.current.offsetHeight);
            setLoaded(true);
        }
    });

    useEffect(() => {
        setChart5Height(text5Ref.current.offsetHeight);
        setInnerHeight(window.innerHeight);
    }, [window.innerHeight]);

    const imageRef = useRef(null);
    const text1Ref = useRef(null);
    const text2Ref = useRef(null);
    const text3Ref = useRef(null);
    const text4Ref = useRef(null);
    const text5Ref = useRef(null);

    useEffect(() => {
        if (loaded) {
            ScrollTrigger.create({
                trigger: "#phone",
                endTrigger: "#text5",
                start: "center center",
                end: () => {
                    return `bottom ${
                        chart5Height + (innerHeight - chart5Height) / 2
                    }px`;
                },
                pin: true,
                pinSpacing: false,
            });
            ScrollTrigger.create({
                trigger: "#text1",
                endTrigger: "#text2",
                start: "top top",
                end: () => {
                    return `bottom ${
                        chart1Height + (innerHeight - chart1Height) / 2
                    }px`;
                },
                pin: true,
                pinSpacing: false,
            });
            ScrollTrigger.create({
                trigger: "#text2",
                endTrigger: "#text3",
                start: "top top",
                end: () => {
                    return `bottom ${
                        chart2Height + (innerHeight - chart2Height) / 2
                    }px`;
                },
                pin: true,
                pinSpacing: false,
            });
            ScrollTrigger.create({
                trigger: "#text3",
                endTrigger: "#text4",
                start: "top top",
                end: () => {
                    return `bottom ${
                        chart3Height + (innerHeight - chart3Height) / 2
                    }px`;
                },
                pin: true,
                pinSpacing: false,
            });
            ScrollTrigger.create({
                trigger: "#text4",
                endTrigger: "#text5",
                start: "top top",
                end: () => {
                    return `bottom ${
                        chart4Height + (innerHeight - chart4Height) / 2
                    }px`;
                },
                pin: true,
                pinSpacing: false,
            });
            gsap.utils.toArray(".steps").forEach((step, index) => {
                ScrollTrigger.create({
                    trigger: step,
                    start: "top top",
                    end: "bottom top",
                    toggleClass: "active",
                    onEnter: () => {
                        // document.getElementById('phone').src=currentFrame[index]
                        imageRef !== null && imageRef.current !== null
                            ? (imageRef.current.src = currentFrame[index])
                            : void 0;
                    },
                    onLeave: () => {
                        // document.getElementById('phone').src=currentFrame[index]
                        imageRef !== null && imageRef.current !== null
                            ? (imageRef.current.src = currentFrame[index])
                            : void 0;
                    },
                    onEnterBack: () => {
                        // document.getElementById('phone').src=currentFrame[index]
                        imageRef !== null && imageRef.current !== null
                            ? (imageRef.current.src = currentFrame[index])
                            : void 0;
                    },
                    onLeaveBack: () => {
                        // document.getElementById('phone') !== null ?
                        // document.getElementById('phone').src=currentFrame[index]: void(0)
                        imageRef !== null && imageRef.current !== null
                            ? (imageRef.current.src = currentFrame[index])
                            : void 0;
                    },
                });
            });
        }
    }, [chart5Height]);
    return (
        <Layout navColor="#131313" background="#131313">
            <Container>
                <div id="title">
                    <Title>
                        <TipSpan>Tips</TipSpan> by developers, <br />
                        for developers.
                    </Title>
                    <SubTitle>
                        ZipTips is an entirely new way to learn{" "}
                        <TextLoop
                            adjustingSpeed={100}
                            interval={[
                                1500,
                                1500,
                                1500,
                                1500,
                                1500,
                                1500,
                                1500,
                                6000,
                            ]}
                        >
                            <span>Apple’s technologies.</span>
                            <span>SwiftUI.</span>
                            <span>Swift Combine.</span>
                            <span>MAPKit.</span>
                            <span>UIKit Dynamics.</span>
                            <span>DisclosureGroup in SwiftUI.</span>
                            <span>UICompositionalLayout.</span>
                            <span>and earn, coding Swift.</span>
                        </TextLoop>
                    </SubTitle>
                    <a href="https://apps.apple.com/us/app/ziptips/id1467116165#?platform=iphone">
                        <DownloadOnAppStoreButton src={DownloadOnAppStore} />
                    </a>
                    <Padding />
                </div>
                <PhoneContainer id="phone">
                    <PhoneImage src={currentFrame[0]} ref={imageRef} />
                </PhoneContainer>
                <TipTextContainer>
                    <TipStepText className="steps" id="text1" ref={text1Ref}>
                        Get Tips - and improve your App with specific iOS
                        features
                    </TipStepText>
                    <TipStepText className="steps" id="text2" ref={text2Ref}>
                        Each Tip includes a Swift Playground
                    </TipStepText>
                    <TipStepText className="steps" id="text3" ref={text3Ref}>
                        Search across all tips to find the code you need
                    </TipStepText>
                    <TipStepText className="steps" id="text4" ref={text4Ref}>
                        If you’re stuck, ask a question and get 1:1 help
                        directly from the Tip’s creator
                    </TipStepText>
                    <TipStepText id="text5" ref={text5Ref}></TipStepText>
                </TipTextContainer>

                <Title id="tipsTitle">
                    Behind every Tip is a creator ready to answer{" "}
                    <TipSpan>your questions.*</TipSpan>
                </Title>
                <CreatorSectionContainer>
                    <CreatorSection data={data.Tip.creatorOne} />
                    <CreatorSection data={data.Tip.creatorTwo} />
                    <CreatorSection data={data.Tip.creatorThree} />
                </CreatorSectionContainer>
                <Disclaimer>*Requires a ZipTips Subscription</Disclaimer>

                <SubTitle>
                    Many times, it’s knowing not what to ask, but who to ask.
                    ZipTips connects you with experts in the iOS technologies
                    you care about.
                </SubTitle>

                <Title id="tipsTitle">
                    Most Popular Tips
                    <br />
                </Title>
                <SubTitle>
                    Data, Kevin and Vladimir earned $250 previously for these
                    Tips.
                </SubTitle>
                <TipContainer>
                    {data.Tip.one ? (
                        <TipRect data={data.Tip.one}></TipRect>
                    ) : null}
                    {data.Tip.two ? (
                        <TipRect data={data.Tip.two}></TipRect>
                    ) : null}
                    {data.Tip.three ? (
                        <TipRect data={data.Tip.three}></TipRect>
                    ) : null}
                </TipContainer>

                <Title id="tipsTitle">
                    Are you a <TipSpan>Senior Developer?</TipSpan>
                </Title>
                <SubTitle>
                    Join a global community of developers sharing their
                    knowledge.
                </SubTitle>
                <LinkContainer>
                    <Link to="/earn">
                        Unlock a new revenue stream creating tips and answering
                        questions
                        <span style={{ margin: "0 0 0 10px" }}>
                            <FontAwesomeIcon icon={faChevronRight} size="xs" />
                        </span>
                    </Link>
                </LinkContainer>
            </Container>
        </Layout>
    );
};

const LinkContainer = styled.a`
    a {
        color: #3b9af8;
        font-family: Circular-Book;
        cursor: pointer;
        text-decoration: none;
        &:hover {
            opacity: 0.7;
        }
    }
`;

const CreatorSectionContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
`;

const Disclaimer = styled.h3`
    color: white;
    font-family: Circular-Book;
    font-size: 0.75rem;
    margin-bottom: 3rem;
`;

const Padding = styled.div`
    width: auto;
    height: 20vh;
    ${mobileAndDown`
        display: none;
    `}
`;

const TipContainer = styled.div`
    display: flex;
    color: white;
    font-family: Circular-Bold;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
`;

const Container = styled.div`
    min-height: 100vh;
    margin: 5vh 5vw;
    ${mobileAndDown`
        margin: 5vh 10vw;
    `}
`;

const DownloadOnAppStoreButton = styled.img`
    cursor: pointer;
    margin-top: 30px;
    width: 150px;
`;

export default Home;
