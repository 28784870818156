import React, {useState} from 'react';
import styled from 'styled-components';
import {useQuery} from "@apollo/react-hooks";
import { toRem, mobileAndDown } from "../utils/styleUtils";

const thumbnail = fullImageURL => fullImageURL.replace(
    /tip-images-website\/ST-(\d{1,3}).jpg/,
    'tip-images-website/thumbnails/ST-$1.png',
);

const CreatorSection = (props) => {
  const {givenName, tipIDs, image, location} = props.data

  return (
    props.data ? 
    <Container>
        <CreatorImage src={thumbnail(image)}/>
        <Text>{givenName}, {location}</Text>
        <SubText>{tipIDs.length} Tips created</SubText>
    </Container> : null
  );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    ${mobileAndDown`
        width: 100%;
    `}
`

const CreatorImage = styled.img`
    width: 50%;
`

const Text = styled.h3`
    font-family: Circular-Book;
    color: white;
    margin: 5px 0;
`

const SubText = styled.h3`
    font-family: Circular-Book;
    color: #BBBBBB;
    margin: 5px 0;
    font-size: 0.75rem;
`

export default CreatorSection;