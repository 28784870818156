import React, {useState} from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import {useQuery} from "@apollo/react-hooks";
import { toRem, mobileAndDown } from "../utils/styleUtils";
import {formatted} from "../utils/formatUtils"

const getAuthorQuery = gql`
  query getAuthor($authorID: ID!) {
    getCreator(id: $authorID) {
      image
      givenName
    }
  }
`

const thumbnail = fullImageURL => fullImageURL.replace(
  /tip-images-website\/ST-(\d{1,3}).jpg/,
  'tip-images-website/thumbnails/ST-$1.png',
);

const AuthorSection = ({ authorID }) => {
  const {data, loading, error} = useQuery(getAuthorQuery, {variables: {authorID}});

  if (loading) return null;
  if (error) return <h3>Error while fetching Author: {error.message}</h3>;
  if (data.getCreator) {
    return (
      <AuthorDiv>
        <AuthorImage src={data.getCreator.image}/>
        <AuthorName>{data.getCreator.givenName}</AuthorName>
      </AuthorDiv>
    );
  } else {
    return null;
  }
};

const TipRect = (props) => {
  const {authorID, id, title, subtitle, introduction, headerImage} = props.data;

  return (
    props.data ? 
    <TipContainer>
      <ContentContainer>
        <TipImage src={thumbnail(headerImage)} alt={title} />
        <AuthorSection authorID={authorID}/>
      </ContentContainer>      
      <TipInformation>
        <TipTitle>{formatted(title)}</TipTitle>
        <TipSubtitle>{subtitle}</TipSubtitle>
      </TipInformation>
      <VerticalLine />
    </TipContainer> : null
  );
}

const TipContainer = styled.div`
  width: 25%;
  min-height: 30vh;
  padding: 2rem;
  background: #1F1F1F;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  transition: all 0.2s;
  margin: 1.5rem;
  margin-left: 0;
  box-shadow: 0 4px 6px rgba(74,74,139,.1), 0 2px 4px rgba(74,74,139,.06);
  * + * {
      margin: 0;
  }
  &:hover {
    box-shadow: 0 10px 20px rgba(74,74,139,.1), 0 8px 18px rgba(74,74,139,.06);
  }
  ${mobileAndDown`
    width: 80%;
  `}
  &:last-child {
      margin-right: 0;
  }
`

const ContentContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

const TipInformation = styled.div`
  width: 100%;
  ${mobileAndDown`
    width: 100%;
  `}
`

const TipImage = styled.img`
  width: ${toRem(80)};
  height: ${toRem(80)};
  object-fit: cover;
  object-position: center;
  border-radius: ${toRem(20)};
  padding: 10px;
`;

const TipTitle = styled.h2`
  color: white;
  font-family: Circular-Bold;
  font-size: ${toRem(16)};
`

const TipSubtitle = styled.h3`
  color: #C4C4C4;
  font-family: Circular-Book;
  font-size: ${toRem(10)};
`

const AuthorDiv = styled.div`
  text-align: center;
  ${mobileAndDown`
    align-self: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  `}
`

const VerticalLine = styled.div`
  border-left: 1px solid #C4C4C4;
  height: 60%;
`

const AuthorImage = styled.img`
  width: ${toRem(64)};
  height: ${toRem(64)};
`

const AuthorName = styled.h2`
  color: white;
  font-family: Circular-Book;
  font-size: ${toRem(12)};
`

export default TipRect